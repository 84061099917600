import * as Yup from 'yup'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import tracking from 'src/tracking'

import { actionButtonMock } from '../../components/atoms/ActionButton/mocks'
import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import { formikFormMocks } from '../../components/form/FormikForm/mock'
import { Icons } from '../../components/atoms/Icon'
import { HeaderPopinAuthProps } from '../../components/molecules/HeaderPopinAuth'
import { actions, selectors } from '../../redux'
import { useServiceRequest } from '../../hooks/useServiceRequest'
import Router, { routes } from '../../routes/Router'
import FormFieldPassword from '../../components/form/fields/FormFieldPassword'
import FormFieldInput from '../../components/form/fields/FormFieldInput'
import {
  getPasswordInputPropsForLogin,
  getUsernameInputProps,
} from '../Common/passwordRules'

const useHeaderPopin = (): {
  headerPopinProps: HeaderPopinAuthProps
  setAuthOpen: (v: boolean) => void
} => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const authOpen = useSelector(selectors.auth.authPopinOpen)
  const setAuthOpen = useCallback(
    (value: boolean) => {
      dispatch(actions.auth.setAuthPopinOpen(value))
      dispatch(actions.auth.loginReset())
    },
    [dispatch]
  )

  //const [authOpen, setAuthOpen] = useState(false)
  const isConnected = useSelector(selectors.auth.isConnected)

  const onLoginComplete = useCallback(() => {
    setAuthOpen(false)
    tracking.authPopin.direct()
  }, [setAuthOpen])

  const [login, loginHandler] = useServiceRequest(
    selectors.auth.login,
    actions.auth.loginRequest,
    null,
    onLoginComplete
  )

  const [, socialLoginHandler] = useServiceRequest(
    selectors.auth.socialLogin,
    actions.auth.socialLoginRequest,
    actions.auth.socialLoginReset,
    onLoginComplete
  )

  const logoutHandler = useCallback(() => {
    dispatch(actions.auth.logout())
  }, [dispatch])

  const headerPopinProps: HeaderPopinAuthProps = isConnected
    ? {
        heroPopin: t('header_auth_popin_title'),
        linkSignUp: {
          label: t('header_auth_popin_logout'),
          onClick: logoutHandler,
        },
        imageProps: {
          maxWidth: 335,
          width: 335,
          height: 60,
          withHD: true,
          alt: t('header_auth_popin_title'),
          images: [
            // todo replace test image
            {
              src: '/static/assets/images/tests/accountHero-min.webp',
              size: 600,
              type: 'images/webp',
            },
            {
              src: '/static/assets/images/tests/accountHero-min.jpg',
              size: 600,
              type: 'images/jpeg',
            },
          ],
        },
        loginButtonProps: {
          label: t('header_auth_popin_account'),
          variant: ActionButtonVariant.yellow,
          href: Router.getRouteUrl(routes.accountPreferences),
        },
        onClose: () => setAuthOpen(false),
        isOpen: authOpen,
        closeButton: t('header_auth_popin_close'),
      }
    : {
        heroPopin: t('header_auth_popin_title'),
        closeButton: t('header_auth_popin_close'),
        signUpText: t('header_auth_popin_no_account'),
        linkSignUp: {
          label: t('header_auth_popin_no_account_label'),
          href: Router.getRouteUrl(routes.signup.name),
        },
        loginSubtitle: t('header_auth_popin_social'),
        imageProps: {
          maxWidth: 335,
          width: 335,
          height: 60,
          withHD: true,
          alt: t('header_auth_popin_title'),
          images: [
            // todo replace test image
            {
              src: '/static/assets/images/tests/accountHero-min.webp',
              size: 600,
              type: 'images/webp',
            },
            {
              src: '/static/assets/images/tests/accountHero-min.jpg',
              size: 600,
              type: 'images/jpeg',
            },
          ],
        },
        loginButtonProps: {
          label: t('header_auth_popin_login'),
        },
        signUpButtonProps: {
          label: t('header_auth_popin_signup'),
          variant: ActionButtonVariant.yellow,
          href: Router.getRouteUrl(routes.signup.name),
          onClick: () => tracking.authPopin.signup(),
        },
        formikForm: {
          ...formikFormMocks.basic,
          initialValues: {
            email: '',
            password: '',
          },
          validationSchema: Yup.object().shape({
            email: Yup.string()
              .email(t('header_auth_popin_login_email_error'))
              .required(t('this_field_is_required')),
            password: Yup.string().required(t('this_field_is_required')),
          }),
          onSubmit: loginHandler,
          validateOnChange: false,
        },
        formikFieldEmailProps: {
          Component: FormFieldInput,
          required: true,
          name: 'email',
          label: t('header_auth_popin_login_email'),
          inputProps: {
            ...getUsernameInputProps(),
          },
        },
        formikFieldPasswordProps: {
          Component: FormFieldPassword,
          required: true,
          name: 'password',
          label: t('header_auth_popin_login_password'),
          inputProps: {
            ...getPasswordInputPropsForLogin(),
          },
        },
        linkResetPasswordProps: {
          label: t('header_auth_popin_login_forgot_password'),
          href: Router.getRouteUrl(routes.requestPassword.name),
        },
        submitButtonText: login?.pending
          ? t('header_auth_popin_login_pending')
          : t('header_auth_popin_login'),
        messagesProps: login?.errors
          ? {
              messages: login.errors,
            }
          : undefined,
        actionFacebookButtonProps: {
          ...actionButtonMock,
          label: t('header_auth_popin_facebook'),
          iconPosition: 'left',
          iconProps: {
            icon: Icons.socialFacebook,
            width: 24,
            height: 24,
          },
          onClick: () => {
            tracking.authPopin.direct('facebook')
            socialLoginHandler({
              provider: 'facebook',
            })
          },
        },
        actionGoogleButtonProps: {
          ...actionButtonMock,
          label: t('header_auth_popin_google'),
          variant: ActionButtonVariant.secondary,
          iconPosition: 'left',
          iconProps: {
            icon: Icons.google,
            width: 24,
            height: 24,
          },
          onClick: () => {
            tracking.authPopin.direct('google')
            socialLoginHandler({
              provider: 'google',
            })
          },
        },
        onClose: () => setAuthOpen(false),
        isOpen: authOpen,
      }
  return { headerPopinProps, setAuthOpen }
}

export default useHeaderPopin
