import { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { SocialsProps } from '../../atoms/Socials'
import { FooterRegisterProps } from '../FooterRegister'
import { LinkProps } from '../../atoms/Link'
import { FooterMenuProps } from '../FooterMenu'
import BackToTop from '../../atoms/BackToTop'
import { NewsletterPushProps } from '../NewsletterPush'

import * as SC from './styled'

export type FooterProps = {
  className?: string
  imageProps: PictureProps
  socialsProps?: SocialsProps
  registerProps?: FooterRegisterProps
  newsletterProps?: NewsletterPushProps
  legalLinks?: LinkProps[]
  menuProps?: FooterMenuProps
  customizeCookies?: LinkProps
}

const Footer: FC<FooterProps> = ({
  className,
  imageProps,
  socialsProps,
  registerProps,
  newsletterProps,
  legalLinks,
  menuProps,
  customizeCookies,
}) => {
  return (
    <>
      <SC.Main className={className}>
        <SC.Wrapper>
          <SC.Head>
            <SC.Section $alignSelf="flex-start">
              <SC.Logo {...imageProps} />
              {socialsProps && <SC.StyledSocial {...socialsProps} />}
            </SC.Section>
            <SC.Section>
              {registerProps && <SC.Register {...registerProps} />}
              {newsletterProps && (
                <SC.Register {...newsletterProps} isNewsletter />
              )}
            </SC.Section>
          </SC.Head>
          <SC.Separator $ishiddenMobile />
          {menuProps && <SC.Menu {...menuProps} />}
          <SC.Separator />
          <SC.LegalLinks>
            {customizeCookies && <SC.Legal {...customizeCookies} />}
            {legalLinks?.map((link, i) => (
              <SC.Legal key={i} {...link} />
            ))}
          </SC.LegalLinks>
        </SC.Wrapper>
      </SC.Main>
      <BackToTop />
    </>
  )
}

export default Footer
