import styled from 'styled-components'

import RegisterPush from '../RegisterPush'
import _NewsletterPush from '../NewsletterPush'
import {
  BackgroundImage,
  CheckMark,
  Content,
  LegalField,
  Legend,
  Overlay,
  Text,
  Title,
} from '../RegisterPush/styled'
import {
  BackgroundImage as _BackgroundImage,
  CheckMark as _CheckMark,
  Content as _Content,
  LegalField as _LegalField,
  Legend as _Legend,
  Overlay as _Overlay,
  Text as _Text,
  Title as _Title,
} from '../NewsletterPush/styled'

export const Register = styled(RegisterPush)`
  border-radius: 0;
  overflow: initial;
  & ${BackgroundImage} {
    display: none;
  }
  & ${Overlay} {
    display: none;
  }
  & ${Content} {
    padding: 0;
    text-align: left;
    align-items: flex-start;
  }
  & ${Title} {
    color: ${({ theme }) => theme.colors.ruby};
  }
  & ${CheckMark} {
    display: none;
  }
  & ${Text} {
    ${({ theme }) => theme.textStyles.textSmall}
    color: ${({ theme }) => theme.colors.bismark};
  }
  & ${Legend} {
    ${({ theme }) => theme.textStyles.textXSmall}
    color: ${({ theme }) => theme.colors.bismark};
    & a {
      color: ${({ theme }) => theme.colors.bismark};
      &:hover {
        color: ${({ theme }) => theme.colors.arapawa};
      }
    }
  }
  & ${LegalField} {
    & label,
    p {
      color: ${({ theme }) => theme.colors.bismark};
    }
    & a {
      color: ${({ theme }) => theme.colors.bismark};
      &:hover {
        color: ${({ theme }) => theme.colors.arapawa};
      }
    }
    & svg {
      border: 1px solid ${({ theme }) => theme.colors.bismark};
    }
  }
`

export const NewsletterPush = styled(_NewsletterPush)`
  border-radius: 0;
  overflow: initial;
  & ${_BackgroundImage} {
    display: none;
  }
  & ${_Overlay} {
    display: none;
  }
  & ${_Content} {
    padding: 0;
    text-align: left;
    align-items: flex-start;
  }
  & ${_Title} {
    color: ${({ theme }) => theme.colors.ruby};
  }
  & ${_CheckMark} {
    display: none;
  }
  & ${_Text} {
    ${({ theme }) => theme.textStyles.textSmall}
    color: ${({ theme }) => theme.colors.bismark};
  }
  & ${_LegalField} {
    & label,
    p {
      color: ${({ theme }) => theme.colors.bismark};
    }
    & a {
      color: ${({ theme }) => theme.colors.bismark};
      &:hover {
        color: ${({ theme }) => theme.colors.arapawa};
      }
    }
    & svg {
      border: 1px solid ${({ theme }) => theme.colors.bismark};
    }
  }
  & ${_Legend} {
    ${({ theme }) => theme.textStyles.textXSmall}
    color: ${({ theme }) => theme.colors.bismark};
    & a {
      color: ${({ theme }) => theme.colors.bismark};
      &:hover {
        color: ${({ theme }) => theme.colors.arapawa};
      }
    }
  }
`
