import React, { FC } from 'react'

import { FormikFormProps } from '../../form/FormikForm'
import CouponCard, { CouponCardProps } from '../CouponCard'
import { FormMessagesProps } from '../../form/FormMessages'
import FormFieldSelect, {
  FormFieldSelectProps,
} from '../../form/fields/FormFieldSelect'
import Loader from '../../atoms/Loader'

import * as SC from './styled'
import { Variant } from './types'

export type CouponCardsBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  formikForm?: FormikFormProps
  messagesProps?: FormMessagesProps
  filters?: FormFieldSelectProps[]
  cards?: CouponCardProps[]
  variant?: Variant
  isLoadingCoupons?: boolean
  noResultsMessage?: string
}

const CouponCardsBlock: FC<CouponCardsBlockProps> = ({
  className,
  htmlTag,
  title,
  filters,
  htmlTitleTag,
  cards,
  variant,
  isLoadingCoupons,
  noResultsMessage,
}) => {
  return (
    <SC.CouponCardsBlock className={className} as={htmlTag}>
      {title && (
        <SC.Title $variant={variant} as={htmlTitleTag}>
          {title}
        </SC.Title>
      )}
      {filters && (
        <SC.Fields>
          {filters?.map((field, index) => (
            <FormFieldSelect key={index} {...field} />
          ))}
        </SC.Fields>
      )}
      {cards && cards.length > 0 ? (
        <SC.Cards $variant={variant}>
          {cards?.map((card, i) => (
            <CouponCard key={i} {...card} />
          ))}
        </SC.Cards>
      ) : isLoadingCoupons ? (
        <SC.LoaderContainer>
          <Loader />
        </SC.LoaderContainer>
      ) : (
        <SC.NoResults
          dangerouslySetInnerHTML={{ __html: noResultsMessage || '' }}
        />
      )}
    </SC.CouponCardsBlock>
  )
}

export default CouponCardsBlock
