import styled, { css } from 'styled-components'

import Icon from '../Icon'
import Link from '../Link'

export enum ActionButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  yellow = 'yellow',
  red = 'red',
  error = 'error',
  outline = 'outline',
}

export const ActionButton = styled(Link)<{
  $active?: boolean
  disabled?: boolean
  $variant?: ActionButtonVariant
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  padding: 0.9rem 1.6rem;

  border-radius: 1rem;
  border-width: 1px;
  border-style: solid;

  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  border-color: ${({ theme, $active, $variant }) =>
    $active
      ? theme.colors.arapawa
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.arapawa
      : $variant === ActionButtonVariant.secondary
      ? theme.colors.greyChateau
      : $variant === ActionButtonVariant.yellow
      ? theme.colors.bismark
      : $variant === ActionButtonVariant.error
      ? theme.colors.error
      : $variant === ActionButtonVariant.red
      ? theme.colors.fireBrick
      : $variant === ActionButtonVariant.outline
      ? theme.colors.pureWhite
      : theme.colors.pureBlack};
  background-color: ${({ $active, theme, $variant }) =>
    $active
      ? theme.colors.arapawa
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.arapawa
      : $variant === ActionButtonVariant.secondary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.yellow
      ? theme.colors.creamCan
      : $variant === ActionButtonVariant.error
      ? theme.colors.error
      : $variant === ActionButtonVariant.red
      ? theme.colors.fireBrick
      : $variant === ActionButtonVariant.outline
      ? 'transparent'
      : theme.colors.aliceBlue};
  color: ${({ $active, theme, $variant }) =>
    $active
      ? theme.colors.pureWhite
      : !$variant || $variant === ActionButtonVariant.primary
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.secondary
      ? theme.colors.arapawa
      : $variant === ActionButtonVariant.yellow
      ? theme.colors.arapawa
      : $variant === ActionButtonVariant.error
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.red
      ? theme.colors.pureWhite
      : $variant === ActionButtonVariant.outline
      ? theme.colors.pureWhite
      : theme.colors.pureBlack};

  ${({ disabled }) =>
    !disabled &&
    css<{
      $active?: boolean
      disabled?: boolean
      $variant?: ActionButtonVariant
    }>`
      cursor: pointer;
      &:hover:not(:disabled),
      a:hover &:not(:disabled),
      [data-hover]:hover + &,
      a:focus-visible &:not(:disabled),
      &:focus-visible:not(:disabled),
      [data-hover]:focus-within + & {
        border-color: ${({ theme, $active, $variant }) =>
          $active
            ? theme.colors.aliceBlue
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.greyChateau
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.arapawa
            : $variant === ActionButtonVariant.yellow
            ? theme.colors.bismark
            : $variant === ActionButtonVariant.error
            ? theme.colors.error
            : $variant === ActionButtonVariant.red
            ? theme.colors.fireBrick
            : $variant === ActionButtonVariant.outline
            ? theme.colors.pureWhite
            : theme.colors.pureBlack};
        background-color: ${({ $active, theme, $variant }) =>
          $active
            ? theme.colors.aliceBlue
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.arapawa
            : $variant === ActionButtonVariant.yellow
            ? theme.colors.arapawa
            : $variant === ActionButtonVariant.error
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.red
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.outline
            ? theme.colors.pureWhite
            : theme.colors.pureWhite};
        color: ${({ $active, theme, $variant }) =>
          $active
            ? theme.colors.pureWhite
            : !$variant || $variant === ActionButtonVariant.primary
            ? theme.colors.arapawa
            : $variant === ActionButtonVariant.secondary
            ? theme.colors.pureWhite
            : $variant === ActionButtonVariant.yellow
            ? theme.colors.creamCan
            : $variant === ActionButtonVariant.error
            ? theme.colors.error
            : $variant === ActionButtonVariant.red
            ? theme.colors.fireBrick
            : $variant === ActionButtonVariant.outline
            ? theme.colors.arapawa
            : theme.colors.pureBlack};
      }
    `}
`

export const Label = styled.span`
  ${({ theme }) => theme.textStyles.titleH7}
  display: flex;
  align-items: center;
  min-height: 2.4rem;
  &:only-child {
    text-align: center;
  }
`

export const ActionIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
`
